import anime from 'animejs';

export default function hideOverlay() {
  const overlay = document.getElementById('overlay-menu');
  if (!overlay) return;

  anime.remove(overlay);
  overlay.dataset.animating = 'hide';

  anime({
    targets: overlay,
    opacity: [overlay.style.opacity || 1, 0],
    duration: 500,
    easing: 'linear',
    complete: () => {
      overlay.style.display = 'none';
      overlay.dataset.animating = '';
    },
  });
}
