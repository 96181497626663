$('.footer-column-header').on('click', function () {
  if ($(window).width() < 700) {
    $('svg', this).toggleClass('rotate');
    $('.footer-column-list[data-id="' + $(this).data('id') + '"]').toggle('300');
  }
});

if ($('.newsletter-component').length > 0) {
  const newsletterColumnHeader = $('.lang-de .newsletter-component .footer-column-header');
  newsletterColumnHeader.attr('data-id', 'newsletter');

  const newsletterColumnList = $('.lang-de .newsletter-component .footer-column-list');
  newsletterColumnList.attr('data-id', 'newsletter');

  const clickedInputs = $(
    '.newsletter-component .footer-column-list .newsletter .newsletter-form .newsletter-form-wrapper input',
  );

  const newsletterSection = $('.newsletter-component');
  const newsletterAcceptText = $('.newsletter-accept-text');

  let isNewsletterAcceptTextVisible = false;

  clickedInputs.on('focus', function () {
    if (!isNewsletterAcceptTextVisible) {
      newsletterAcceptText.addClass('visible');
      isNewsletterAcceptTextVisible = true;
    }
  });

  $(document).on('click', function (event) {
    if (!$(event.target).closest(newsletterSection).length && isNewsletterAcceptTextVisible) {
      newsletterAcceptText.removeClass('visible');
      isNewsletterAcceptTextVisible = false;
    }
  });
}
