$('.modal-header .close-modal, .modal-backdrop').on('click', function () {
  $('.newsletter-modal .modal-dialog').fadeOut(300);
  $('.modal-backdrop').fadeOut(300);
  $('body').removeClass('overflow-hidden');
});

$('#newsletter-modal .termsandprivacy-formfields .form-terms input[type=checkbox]').on('change', function () {
  if (!$('#newsletter-modal .termsandprivacy-formfields .form-terms input[type=checkbox]').not(':checked').length) {
    $('#newsletter-modal .termsandprivacy-formfields input[name=select-all]').prop('checked', true);
  } else {
    $('#newsletter-modal .termsandprivacy-formfields input[name=select-all]').prop('checked', false);
  }
});
