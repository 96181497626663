import Swiper from 'swiper';
import { A11y, Autoplay, Navigation } from 'swiper/modules';

const carouselList = document.querySelectorAll('.swiper-carousel');

document.addEventListener('DOMContentLoaded', () => initCarousels(carouselList));

export function initCarousels(carouselList) {
  carouselList.forEach(carousel => {
    let isAutoPlay = carousel.classList.contains('autoplay');
    let isLoop = carousel.classList.contains('loop');
    let countSlides = carousel.querySelectorAll('.swiper-slide').length;

    new Swiper(carousel, {
      modules: [Navigation, A11y, Autoplay],
      slidesPerView: 1,
      spaceBetween: 40,
      loop: isLoop,
      autoplay: isAutoPlay,
      navigation: {
        nextEl: '.btn-carousel-next',
        prevEl: '.btn-carousel-prev',
      },
      breakpoints: {
        700: {
          slidesPerView: countSlides < 2 ? countSlides : 2,
        },
        992: {
          slidesPerView: countSlides < 3 ? countSlides : 3,
        },
        1440: {
          slidesPerView: countSlides < 4 ? countSlides : 4,
        },
      },
    });
  });

  document.querySelectorAll('.menu-link').forEach(button => {
    button.addEventListener(
      'click',
      () => {
        if (button.classList.contains('current')) {
          return;
        }

        const targetId = button.dataset.id;
        const targetDiv = document.getElementById(targetId);

        if (targetDiv && targetDiv.querySelector('.swiper-carousel')) {
          const targetCarousels = targetDiv.querySelectorAll('.swiper-carousel');

          targetCarousels.forEach(carousel => {
            if (carousel.swiper) {
              carousel.swiper.destroy(true, true);
            }
          });

          setTimeout(() => {
            if (!targetCarousels[0].swiper) {
              initCarousels(targetCarousels);
            }
          }, 1);
        }
      },
      { capture: true },
    );
  });
}
