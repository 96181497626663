import anime from 'animejs';
import showOverlay from './common/showOverlay';
import hideOverlay from './common/hideOverlay';
import { dynamicOverlayMargin } from './common/header';

function trapFocus(modal) {
  const focusableElements = modal.querySelectorAll(
    'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])',
  );
  if (focusableElements.length === 0) return;
  const firstFocusableElement = focusableElements[0];
  const lastFocusableElement = focusableElements[focusableElements.length - 1];

  modal.addEventListener('keydown', function (e) {
    if (e.key === 'Tab') {
      const focusedElement = document.activeElement;
      if (e.shiftKey) {
        if (focusedElement === firstFocusableElement) {
          e.preventDefault();
          lastFocusableElement.focus();
        }
      } else {
        if (focusedElement === lastFocusableElement) {
          e.preventDefault();
          firstFocusableElement.focus();
        }
      }
    }
  });
}

function openModal(modalContainer) {
  const cartDrawer = modalContainer.querySelector('.cart-drawer');

  document.querySelectorAll('#blockcart-wrapper .body').forEach(el => el.classList.remove('slide-in'));
  document.body.classList.add('overflow-hidden');
  document.body.style.overflow = 'hidden';
  window.innerWidth < 992
    ? dynamicOverlayMargin(document.getElementById('overlay-menu'))
    : (document.getElementById('overlay-menu').style.top = '0px');
  window.innerWidth < 992 ? dynamicOverlayMargin(cartDrawer) : (cartDrawer.style.top = '0px');
  showOverlay();

  if (cartDrawer) {
    cartDrawer.style.transform = 'translateX(100%)';
    anime({
      targets: cartDrawer,
      translateX: ['100%', '0%'],
      duration: 500,
      easing: 'easeInOutExpo',
    });
  }
}

export function closeModal(modalContainer, source = '') {
  const cartDrawer = modalContainer.querySelector('.cart-drawer');
  const cartDrawerWrapper = document.getElementById('blockcart-modal');
  if (cartDrawer) {
    cartDrawer.style.transformOrigin = 'right center';
    anime({
      targets: cartDrawer,
      translateX: ['0%', '100%'],
      duration: 500,
      easing: 'easeInOutExpo',
      complete: function () {
        cartDrawerWrapper?.remove();
      },
    });
  }
  document.body.style.overflow = 'auto';
  document.body.classList.remove('overflow-hidden');
  source !== 'cart' && source !== 'menu' && source !== 'langBox' && source !== 'phone' && hideOverlay();
}

function attachCloseHandlers(modalContainer) {
  modalContainer.addEventListener('click', function (e) {
    if (e.target.closest('.close-modal')) {
      closeModal(modalContainer);
    }
  });
  document.getElementById('overlay-menu').addEventListener(
    'click',
    function () {
      closeModal(modalContainer);
    },
    { once: true },
  );
}

prestashop.blockcart = prestashop.blockcart || {};

prestashop.blockcart.showModal = function (html) {
  const existingModal = document.querySelector('.blockcart-modal');
  if (existingModal) {
    existingModal.remove();
  }
  document.body.insertAdjacentHTML('beforeend', html);
  const modalContainer = document.querySelector('.blockcart-modal');
  if (modalContainer) {
    trapFocus(modalContainer);
    const focusableElements = modalContainer.querySelectorAll(
      'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );
    if (focusableElements.length) {
      focusableElements[0].focus();
    }
    openModal(modalContainer);
    attachCloseHandlers(modalContainer);
  }
};

document.addEventListener('DOMContentLoaded', function () {
  prestashop.blockcart = prestashop.blockcart || {};

  var showModal =
    prestashop.blockcart.showModal ||
    function (modalHtml) {
      document.body.insertAdjacentHTML('beforeend', modalHtml);
      const blockCartModalElement = document.getElementById('blockcart-modal');
      if (blockCartModalElement) {
        blockCartModalElement.addEventListener(
          'click',
          function (event) {
            if (event.target.id === 'blockcart-modal') {
              event.target.remove();
            }
          },
          { once: true },
        );
      }
    };

  prestashop.on('updateCart', function (event) {
    const blockcartEl = document.querySelector('.blockcart');
    if (!blockcartEl) return;
    const refreshURL = blockcartEl.getAttribute('data-refresh-url');
    let requestData = {};
    if (event && event.reason && typeof event.resp !== 'undefined' && !event.resp.hasError) {
      requestData = {
        id_customization: event.reason.idCustomization,
        id_product_attribute: event.reason.idProductAttribute,
        id_product: event.reason.idProduct,
        action: event.reason.linkAction,
      };
    }
    if (event && event.resp && event.resp.hasError) {
      prestashop.emit('showErrorNextToAddtoCartButton', {
        errorMessage: event.resp.errors.join('<br/>'),
      });
    }
    fetch(refreshURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(requestData),
    })
      .then(response => response.json())
      .then(resp => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = resp.preview;
        const newBlockcart = tempDiv.querySelector('.blockcart');
        const oldBlockcart = document.querySelector('.blockcart');
        if (newBlockcart && oldBlockcart) {
          oldBlockcart.replaceWith(newBlockcart);
        }
        if (resp.modal) {
          showModal(resp.modal);
        }
      })
      .catch(err => {
        prestashop.emit('handleError', { eventType: 'updateShoppingCart', resp: err });
      });
  });
});
