import anime from 'animejs';

export default function showOverlay() {
  const overlay = document.getElementById('overlay-menu');
  if (!overlay) return;

  if (overlay.dataset.animating === 'hide') {
    anime.remove(overlay);
    overlay.style.display = 'none';
    overlay.dataset.animating = '';
  }

  if (getComputedStyle(overlay)?.display !== 'none') return;

  overlay.style.display = 'block';
  overlay.style.opacity = 0;
  anime({
    targets: overlay,
    opacity: [0, 1],
    duration: 500,
    easing: 'linear',
  });
}
