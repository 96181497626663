export function initDialog(dialogClass) {
  const dialog = document.querySelector(`.${dialogClass}`);
  const close_button = dialog.querySelector('.close-dialog-icon');
  const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
  const firstFocusableElement = dialog.querySelectorAll(focusableElements)[0];
  const focusableContent = dialog.querySelectorAll(focusableElements);
  const lastFocusableElement = focusableContent[focusableContent.length - 1];

  dialog.addEventListener('click', event => {
    if (event.target === dialog) {
      closeDialog(dialog);
    }
  });

  close_button.addEventListener('click', () => {
    closeDialog(dialog);
  });

  close_button.addEventListener('keydown', event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      closeDialog(dialog);
    }
  });

  dialog.addEventListener('keydown', event => {
    if (event.key === 'Tab') {
      if (event.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          event.preventDefault();
          lastFocusableElement.focus();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          event.preventDefault();
          firstFocusableElement.focus();
        }
      }
    }

    if (event.key === 'Escape') {
      event.preventDefault();
      closeDialog(dialog);
    }
  });

  return dialog;
}

export function openDialog(dialog) {
  const body = document.querySelector('body');

  dialog.showModal();
  body.classList.add('overflow-hidden');

  const focusableElements = dialog.querySelectorAll(
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
  );
  if (focusableElements.length > 0) {
    focusableElements[0].focus();
  }
}

export function closeDialog(dialog) {
  const body = document.querySelector('body');

  dialog.close();
  body.classList.remove('overflow-hidden');
}
