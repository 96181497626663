import { slideToggle } from './slideToggle';

document.querySelectorAll('.footer-column-header').forEach(header => {
  header.addEventListener('click', () => {
    if (window.innerWidth < 700) {
      header.querySelectorAll('svg').forEach(svgEl => {
        svgEl.classList.toggle('rotate');
      });

      const dataId = header.getAttribute('data-id');

      document.querySelectorAll(`.footer-column-list[data-id="${dataId}"]`).forEach(listEl => {
        slideToggle(listEl, 'block', 300);
      });
    }
  });
});

const newsletterComponent = document.querySelector('.newsletter-component');
if (newsletterComponent) {
  document.querySelectorAll('.lang-de .newsletter-component .footer-column-header').forEach(elem => {
    elem.setAttribute('data-id', 'newsletter');
  });
  document.querySelectorAll('.lang-de .newsletter-component .footer-column-list').forEach(elem => {
    elem.setAttribute('data-id', 'newsletter');
  });

  const clickedInputs = document.querySelectorAll(
    '.newsletter-component .footer-column-list .newsletter .newsletter-form .newsletter-form-wrapper input',
  );

  const newsletterAcceptText = document.querySelector('.newsletter-accept-text');
  let isNewsletterAcceptTextVisible = false;

  clickedInputs.forEach(input => {
    input.addEventListener('focus', () => {
      if (!isNewsletterAcceptTextVisible && newsletterAcceptText) {
        newsletterAcceptText.classList.add('visible');
        isNewsletterAcceptTextVisible = true;
      }
    });
  });

  document.addEventListener('click', event => {
    if (!newsletterComponent.contains(event.target) && isNewsletterAcceptTextVisible && newsletterAcceptText) {
      newsletterAcceptText.classList.remove('visible');
      isNewsletterAcceptTextVisible = false;
    }
  });
}
