prestashop.blockcart = prestashop.blockcart || {};

prestashop.blockcart.showModal = html => {
  function getBlockCartModal() {
    $('#blockcart-wrapper .body').removeClass('slide-in');
    $('body').addClass('overflow-hidden');
    $('.dialog-blockcart-backdrop').show();
    return $('.blockcart-modal');
  }

  let $blockCartModal = getBlockCartModal();
  if ($blockCartModal.length) {
    $blockCartModal.remove();
  }
  $('body').append(html);
  $blockCartModal = getBlockCartModal();

  if ($blockCartModal.length) {
    trapFocus($blockCartModal);

    const focusableElements = $blockCartModal.find(
      'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])',
    );
    if (focusableElements.length) {
      focusableElements.first().focus();
    }
  }

  $blockCartModal.on('click', '.close-modal', function () {
    $blockCartModal.fadeOut(300);
    fadeOutAndRemove($('.dialog-blockcart-backdrop'), 300);
    $('body').removeClass('overflow-hidden');
  });

  $('.dialog-blockcart-backdrop').on('click', function () {
    if ($blockCartModal.length) {
      fadeOutAndRemove($blockCartModal, 300);
      fadeOutAndRemove($('.dialog-blockcart-backdrop'), 300);
      $('body').removeClass('overflow-hidden');
    }
  });
};

function fadeOutAndRemove(object, delay) {
  $(object).fadeOut(delay, () => {
    $(object).remove();
  });
}

function trapFocus(modal) {
  const focusableElements = modal.find('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
  const firstFocusableElement = focusableElements.first();
  const lastFocusableElement = focusableElements.last();

  modal.on('keydown', e => {
    if (e.key === 'Tab') {
      const focusedElement = $(document.activeElement);

      if (e.shiftKey) {
        if (focusedElement.is(firstFocusableElement)) {
          e.preventDefault();
          lastFocusableElement.focus();
        }
      } else {
        if (focusedElement.is(lastFocusableElement)) {
          e.preventDefault();
          firstFocusableElement.focus();
        }
      }
    }
  });
}
