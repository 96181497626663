import Swiper from 'swiper';
import { A11y, Autoplay, Navigation } from 'swiper/modules';

const carouselList = document.querySelectorAll('.swiper-carousel');

document.addEventListener('DOMContentLoaded', () => initCarousels(carouselList));

export function initCarousels(carouselList) {
  carouselList.forEach(carousel => {
    let isAutoPlay = carousel.classList.contains('autoplay');
    let isLoop = carousel.classList.contains('loop');

    new Swiper(carousel, {
      modules: [Navigation, A11y, Autoplay],
      slidesPerView: 1,
      spaceBetween: 40,
      loop: isLoop,
      autoplay: isAutoPlay,
      navigation: {
        nextEl: '.btn-carousel-next',
        prevEl: '.btn-carousel-prev',
      },
      breakpoints: {
        700: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 3,
        },
        1440: {
          slidesPerView: 4,
        },
      },
    });
  });
}
