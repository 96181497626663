import anime from 'animejs';

export const slideUp = (element, duration = 300) => {
  const fullHeight = element.scrollHeight;
  element.style.height = fullHeight + 'px';

  anime({
    targets: element,
    height: 0,
    duration: duration,
    easing: 'easeInOutQuad',
    complete: () => {
      element.style.display = 'none';
      element.style.height = 'auto';
    },
  });
};

export const slideDown = (element, targetDisplay, duration = 300) => {
  element.style.display = targetDisplay;
  const fullHeight = element.scrollHeight;
  element.style.height = '0px';

  anime({
    targets: element,
    height: fullHeight,
    duration: duration,
    easing: 'easeInOutQuad',
    complete: () => {
      element.style.height = 'auto';
    },
  });
};

export const slideToggle = (element, targetDisplay, duration = 300) => {
  if (window.getComputedStyle(element).display === 'none') {
    slideDown(element, targetDisplay, duration);
  } else {
    slideUp(element, duration);
  }
};
