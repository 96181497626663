import anime from 'animejs';
import { initDialog, openDialog } from '../common/dialog';
import showOverlay from './showOverlay';
import hideOverlay from './hideOverlay';
import { closeModal } from '../cart-modal';

const languageSelector = document.querySelector('.language_selector');
const phoneNumbers = document.getElementById('phone-numbers');
const blockcartWrapper = document.getElementById('blockcart-wrapper');
let cartAnimation;
let menuAnimation;

const isMobile = () => window.innerWidth < 992;

if (languageSelector) {
  ['mouseover', 'click'].forEach(ev => {
    languageSelector.addEventListener(ev, () => {
      !isMobile() && openLangBox();
      dynamicElementHeight(document.querySelector('.languages-wrapper-box'), 'maxHeight', 60);
      dynamicElementHeight(document.querySelector('.languages-box'), 'maxHeight', 80);
    });
  });

  languageSelector.addEventListener('mouseleave', () => !isMobile() && closeLangBox());
}

if (blockcartWrapper) {
  blockcartWrapper.addEventListener('click', event => {
    if (!event.target.closest('.header') || !isCartEmpty()) return;

    const bodyElement = blockcartWrapper.querySelector('.body');
    if (bodyElement?.classList.contains('slide-in')) {
      closeCart('cart');
      return;
    }

    closeMenu('cart');
    closeLangBox('cart');
    closePhoneNumbers('cart');
    document.querySelector('.blockcart-modal') && closeModal(document.querySelector('.blockcart-modal'), 'cart');
    dynamicElementHeight(blockcartWrapper.querySelector('.body-in'), 'height', 0);
    dynamicOverlayMargin(document.getElementById('overlay-menu'));
    isMobile()
      ? dynamicOverlayMargin(document.getElementById('overlay-menu'))
      : (document.getElementById('overlay-menu').style.top = '0px');
    isMobile() ? dynamicOverlayMargin(bodyElement) : (bodyElement.style.top = '0px');
    openCart();
    showOverlay();
  });

  blockcartWrapper.addEventListener('click', event => {
    if (event.target.closest('.close-cart-icon')) {
      closeCart();
    }

    if (event.target.closest('.cart-link-icon')) {
      event.preventDefault();
    }
  });
}

if (phoneNumbers) {
  phoneNumbers.addEventListener('mouseover', () => !isMobile() && openPhoneNumbers());
  phoneNumbers.addEventListener('mouseleave', () => !isMobile() && closePhoneNumbers());
  phoneNumbers.addEventListener('click', () => {
    if (phoneNumbers.querySelector('.phone-numbers-wrapper').classList.contains('slide-in')) {
      closePhoneNumbers('phone');
    } else {
      closeCart('phone');
      closeLangBox('phone');
      closeMenu('phone');
      document.querySelector('.blockcart-modal') && closeModal(document.querySelector('.blockcart-modal'), 'phone');
      openPhoneNumbers();
    }
  });
}

document.getElementById('overlay-menu')?.addEventListener('click', () => {
  if (isMobile()) {
    closeMenu('overlay');
    closeLangBox('overlay');
    closePhoneNumbers('overlay');
  }

  closeCart('overlay');
});

document.getElementById('open-menu')?.addEventListener('click', () => {
  if (document.getElementById('mobile-menu').classList.contains('slide-in')) {
    closeMenu('menu');
  } else {
    closeCart('menu');
    closeLangBox('menu');
    closePhoneNumbers('menu');
    document.querySelector('.blockcart-modal') && closeModal(document.querySelector('.blockcart-modal'), 'menu');
    dynamicElementHeight(document.getElementById('mobile-menu'), 'height', 80);
    dynamicOverlayMargin(document.getElementById('overlay-menu'));
    openMenu();
  }
});

document.querySelector('.lang-selector-mobile')?.addEventListener('click', function () {
  if (isMobile) {
    this.classList.toggle('active');
  }
});

document.querySelector('.currency-selector-mobile')?.addEventListener('click', function () {
  if (isMobile()) {
    this.classList.toggle('active');
  }
});

document.querySelectorAll('.menu-item-wrapper').forEach(menuItem => {
  menuItem.addEventListener('click', function () {
    if (isMobile() && this.querySelector('.has-child')) {
      this.classList.toggle('active');
    }
  });
});

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.selectorSwitcher')) {
    const selectorDialog = initDialog('selector-dialog');
    document.querySelector('.selectorSwitcher').addEventListener('click', function () {
      openDialog(selectorDialog);
    });
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const links = document.querySelectorAll('.smooth-scroll');

  links.forEach(link => {
    link.addEventListener('click', function (event) {
      event.preventDefault();
      const targetId = this.getAttribute('href').split('#')[1];
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
        });
      } else {
        window.location.href = this.getAttribute('href');
      }
    });
  });
});

function openMenu() {
  document.body.style.overflow = 'hidden';
  document.getElementById('mobile-menu')?.classList.add('slide-in');
  document
    .getElementById('open-menu')
    ?.querySelector('img')
    ?.setAttribute('src', '/themes/vasco-theme/img/icon-end.svg');

  anime({
    targets: '#mobile-menu',
    translateX: 300,
    duration: 500,
    easing: 'easeInOutExpo',
  });

  isMobile() && showOverlay();
}

function closeMenu(source) {
  document.body.style.overflow = 'auto';
  document.getElementById('mobile-menu')?.classList.remove('slide-in');
  document
    .getElementById('open-menu')
    ?.querySelector('img')
    ?.setAttribute('src', '/themes/vasco-theme/img/icon-burger.svg');
  document.querySelectorAll('#mobile-menu .menu-item-wrapper').forEach(item => item.classList.remove('active'));
  document.querySelectorAll('.lang-selector-mobile').forEach(item => item.classList.remove('active'));

  if (menuAnimation == true) {
    return;
  }
  menuAnimation = true;

  anime({
    targets: '#mobile-menu',
    translateX: 0,
    duration: 500,
    easing: 'easeInOutExpo',
    complete: () => {
      menuAnimation = false;
    },
  });

  source !== 'cart' && source !== 'langBox' && source !== 'phone' && hideOverlay();
}

function openCart() {
  const blockcartWrapperBody = document.getElementById('blockcart-wrapper')?.querySelector('.body');
  if (blockcartWrapperBody) {
    blockcartWrapperBody.classList.add('slide-in');
  }

  document.body.style.overflow = 'hidden';

  anime({
    targets: '#blockcart-wrapper .body',
    width: window.innerWidth < 700 ? 320 : 480,
    duration: 500,
    easing: 'easeInOutExpo',
  });

  showOverlay();
}

function closeCart(source = '') {
  const blockcartWrapperBody = document.getElementById('blockcart-wrapper')?.querySelector('.body');
  if (cartAnimation == true) {
    return;
  }
  cartAnimation = true;

  anime({
    targets: '#blockcart-wrapper .body',
    width: 0,
    duration: 500,
    easing: 'easeInOutExpo',
    complete: () => {
      if (blockcartWrapperBody) {
        blockcartWrapperBody.classList.remove('slide-in');
      }
      cartAnimation = false;
    },
  });
  document.body.style.overflow = 'auto';

  source !== 'menu' && source !== 'langBox' && source !== 'phone' && hideOverlay();
}

function openLangBox() {
  document.querySelector('.languages-wrapper-box')?.classList.add('slide-in');
}

function closeLangBox(source = '') {
  document.querySelector('.languages-wrapper-box')?.classList.remove('slide-in');

  source !== 'menu' && source !== 'cart' && source !== 'phone' && hideOverlay();
}

function openPhoneNumbers() {
  document.querySelector('.phone-numbers-wrapper')?.classList.add('slide-in');

  if (isMobile()) {
    document.body.style.overflow = 'hidden';
    showOverlay();
  }
}

function closePhoneNumbers(source = '') {
  document.querySelector('.phone-numbers-wrapper')?.classList.remove('slide-in');

  if (isMobile()) {
    document.body.style.overflow = 'auto';
  }

  source !== 'menu' && source !== 'cart' && hideOverlay();
}

function isCartEmpty() {
  const cartCount = parseInt(document.querySelector('#blockcart-wrapper .cart-count')?.textContent);
  return cartCount ? true : false;
}

export function dynamicOverlayMargin(targetElement) {
  const visibleHeight = getVisibleHeight(document.getElementById('banners-wrapper'));
  targetElement.style.top = `calc(80px + ${visibleHeight}px)`;
}

export function dynamicElementHeight(targetElement, styleElement, fixedpixelvalue) {
  const visibleHeight =
    getVisibleHeight(document.getElementById('banners-wrapper')) +
    getVisibleHeight(document.getElementById('geolocation-banner'));
  targetElement.style[styleElement] = `calc(100dvh - ${fixedpixelvalue}px - ${visibleHeight}px)`;
}

function getVisibleHeight(element) {
  if (element) {
    const elementRect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    const isBottomVisible = elementRect.bottom > 0 && elementRect.bottom <= windowHeight;
    const isTopVisible = elementRect.top >= 0 && elementRect.top < windowHeight;

    if (isTopVisible || isBottomVisible) {
      const visibleHeight = Math.min(elementRect.bottom, windowHeight) - Math.max(elementRect.top, 0);
      return visibleHeight;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}
