const tooltipList = document.querySelectorAll('.tooltip');
const checkTooltipSpace = tooltip => {
  let headerHeight = document.querySelector('#header').offsetHeight;
  return tooltip.getBoundingClientRect().top - headerHeight;
};
tooltipList.forEach(tooltipElement => {
  tooltipElement.addEventListener('mouseover', function () {
    let tooltipElementBefore = window.getComputedStyle(tooltipElement, ':before');
    let tooltipElementBeforeHeight = parseInt(tooltipElementBefore.height);
    tooltipElement.classList.toggle('tooltip-top', tooltipElementBeforeHeight >= checkTooltipSpace(tooltipElement));
  });
});
