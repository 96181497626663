import anime from 'animejs';
import { initDialog, openDialog } from '../common/dialog';

$('.language_selector').on('mouseover click', function () {
  if (window.innerWidth >= 992) openLangBox();
  dynamicElementHeight(document.querySelector('.languages-wrapper-box'), 'maxHeight', 60);
  dynamicElementHeight(document.querySelector('.languages-box'), 'maxHeight', 80);
});

$('.language_selector').on('mouseleave', function () {
  if (window.innerWidth >= 992) closeLangBox();
});

$('.languages-wrapper-box').on('mouseleave', function () {
  if (window.innerWidth >= 992) closeLangBox();
});

$('.language_selector').on('click', function () {
  if (window.innerWidth < 992) {
    if ($('.languages-wrapper-box').hasClass('slide-in')) {
      closeLangBox();
    } else {
      closeMenu('langBox');
      closeCart('langBox');
      closePhoneNumbers('langbox');
      openLangBox();
    }
  }
});

$('#open-menu').on('click', function () {
  if ($('#mobile-menu').hasClass('slide-in')) {
    closeMenu('menu');
  } else {
    closeCart('menu');
    closeLangBox('menu');
    closePhoneNumbers('menu');
    dynamicElementHeight(document.getElementById('mobile-menu'), 'height', 80);
    dynamicOverlayMargin(document.getElementById('overlay-menu'));
    openMenu();
  }
});

$('#blockcart-wrapper').on('click', '.header', function () {
  if (isCartEmpty()) {
    if ($(window).width() < 992) {
      if ($('.body').hasClass('slide-in')) {
        closeCart('cart');
      } else {
        closeMenu('cart');
        closeLangBox('cart');
        closePhoneNumbers('cart');
        dynamicElementHeight(document.getElementById('blockcart-wrapper').querySelector('.body-in'), 'height', 80);
        dynamicOverlayMargin(document.getElementById('overlay-menu'));
        openCart();
      }
    }
  }
});

$('#blockcart-wrapper').on('click', '.close-cart-icon', function () {
  if ($(window).width() < 992) {
    closeCart();
  }
});

$('#blockcart-wrapper').on('click', '.cart-link-icon', function (e) {
  if ($(window).width() < 992) {
    e.preventDefault();
  }
});

$('#overlay-menu').on('click', function () {
  if ($(window).width() < 992) {
    closeMenu('overlay');
    closeCart('overlay');
    closeLangBox('overlay');
    closePhoneNumbers('overlay');
  }
});

$('#blockcart-wrapper').on('mouseover', function () {
  if (window.innerWidth >= 992 && isCartEmpty()) {
    dynamicElementHeight(document.querySelector('.body'), 'maxHeight', 60);
    dynamicElementHeight(document.querySelector('.body-in'), 'maxHeight', 80);
    openCart();
  }
});

$('#blockcart-wrapper').on('mouseleave', function () {
  if (window.innerWidth >= 992) {
    closeCart();
  }
});

$('#phone-numbers').on('mouseover', function () {
  if ($(window).width() >= 992) openPhoneNumbers();
});

$('#phone-numbers').on('mouseleave', function () {
  if ($(window).width() >= 992) closePhoneNumbers();
});

$('#phone-numbers').on('click', function () {
  if ($('.phone-numbers-wrapper').hasClass('slide-in')) {
    closePhoneNumbers('phone');
  } else {
    closeCart('phone');
    closeLangBox('phone');
    closeMenu('phone');
    openPhoneNumbers();
  }
});

$('.menu-item-wrapper').on('click', function () {
  if ($(window).width() < 992) {
    $(this).find('has-child') ? $(this).toggleClass('active') : '';
  }
});

$('.lang-selector-mobile').on('click', function () {
  if ($(window).width() < 992) {
    $(this).toggleClass('active');
  }
});
$('.currency-selector-mobile').on('click', function () {
  if ($(window).width() < 992) {
    $(this).toggleClass('active');
  }
});

function openMenu() {
  $('body').css('overflow', 'hidden');

  $('#mobile-menu').addClass('slide-in');
  $('#open-menu').find('img').attr('src', '/themes/vasco-theme/img/icon-end.svg');

  anime({
    targets: '#mobile-menu',
    translateX: 300,
    duration: 500,
    easing: 'easeInOutExpo',
  });

  if ($(window).width() < 992) {
    showOverlay();
  }
}

function closeMenu(source) {
  $('body').css('overflow', 'auto');
  $('#mobile-menu').removeClass('slide-in');
  $('#open-menu').find('img').attr('src', '/themes/vasco-theme/img/icon-burger.svg');
  $('#mobile-menu .menu-item-wrapper').removeClass('active');
  $('.lang-selector-mobile').removeClass('active');

  anime({
    targets: '#mobile-menu',
    translateX: 0,
    duration: 500,
    easing: 'easeInOutExpo',
  });

  source !== 'cart' && source !== 'langBox' && source !== 'phone' && hideOverlay();
}

function openCart() {
  $('#blockcart-wrapper .body').addClass('slide-in');

  if (window.innerWidth < 992) {
    anime({
      targets: '#blockcart-wrapper .body',
      width: 260,
      duration: 500,
      easing: 'easeInOutExpo',
    });

    $('body').css('overflow', 'hidden');
    showOverlay();
  } else {
    $('#blockcart-wrapper .body').css('width', '260px');
    $('#blockcart-wrapper .body-in').css('height', 'auto');
  }
}

function closeCart(source = '') {
  $('#blockcart-wrapper .body').removeClass('slide-in');

  if ($(window).width() < 992) {
    anime({
      targets: '#blockcart-wrapper .body',
      width: 0,
      duration: 500,
      easing: 'easeInOutExpo',
    });
    $('body').css('overflow', 'auto');
  } else {
    $('#blockcart-wrapper .body').css('width', '0px');
  }

  source !== 'menu' && source !== 'langBox' && source !== 'phone' && hideOverlay();
}

function openLangBox() {
  $('.languages-wrapper-box').addClass('slide-in');

  if ($(window).width() < 992) {
    $('body').css('overflow', 'hidden');
    showOverlay();
  }
}

function closeLangBox(source = '') {
  $('.languages-wrapper-box').removeClass('slide-in');

  if ($(window).width() < 992) {
    $('body').css('overflow', 'auto');
  }

  source !== 'menu' && source !== 'cart' && source !== 'phone' && hideOverlay();
}

function openPhoneNumbers() {
  $(document).ready(function () {
    $('.phone-numbers-wrapper').addClass('slide-in');

    if ($(window).width() < 992) {
      $('body').css('overflow', 'hidden');
      showOverlay();
    }
  });
}

function closePhoneNumbers(source = '') {
  $('.phone-numbers-wrapper').removeClass('slide-in');

  if ($(window).width() < 992) {
    $('body').css('overflow', 'auto');
  }

  source !== 'menu' && source !== 'cart' && hideOverlay();
}

function isCartEmpty() {
  let cart_count = parseInt($('#blockcart-wrapper .cart-count').html());
  return cart_count ? true : false;
}

function showOverlay() {
  $('#overlay-menu').fadeIn(500);
}

function hideOverlay() {
  $('#overlay-menu').fadeOut(500);
}

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.selectorSwitcher')) {
    const selectorDialog = initDialog('selector-dialog');
    document.querySelector('.selectorSwitcher').addEventListener('click', function () {
      openDialog(selectorDialog);
    });
  }
});

function dynamicOverlayMargin(targetElement) {
  const visibleHeight = getVisibleHeight(document.getElementById('banners-wrapper'));
  targetElement.style.top = `calc(80px + ${visibleHeight}px)`;
}

function dynamicElementHeight(targetElement, styleElement, fixedpixelvalue) {
  const visibleHeight =
    getVisibleHeight(document.getElementById('banners-wrapper')) +
    getVisibleHeight(document.getElementById('geolocation-banner'));
  targetElement.style[styleElement] = `calc(100dvh - ${fixedpixelvalue}px - ${visibleHeight}px)`;
}

function getVisibleHeight(element) {
  if (element) {
    const elementRect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    const isBottomVisible = elementRect.bottom > 0 && elementRect.bottom <= windowHeight;
    const isTopVisible = elementRect.top >= 0 && elementRect.top < windowHeight;

    if (isTopVisible || isBottomVisible) {
      const visibleHeight = Math.min(elementRect.bottom, windowHeight) - Math.max(elementRect.top, 0);
      return visibleHeight;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

document.addEventListener('DOMContentLoaded', function () {
  const links = document.querySelectorAll('.smooth-scroll');

  links.forEach(link => {
    link.addEventListener('click', function (event) {
      event.preventDefault();
      const targetId = this.getAttribute('href').split('#')[1];
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: 'smooth',
        });
      } else {
        window.location.href = this.getAttribute('href');
      }
    });
  });
});
